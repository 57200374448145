// material-ui
import { Autocomplete, Box, CircularProgress, Stack, TextField, Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';

// assets
import { IconSearch, IconCornerDownLeft } from '@tabler/icons-react';
import { useTheme } from '@mui/material/styles';
import { cloneElement, useEffect, useState } from 'react';
import { IconNotebook, IconUserSquareRounded, IconBuildingSkyscraper } from '@tabler/icons-react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import messages from './messages';
import { utilityApi, useLazyGlobalSearchQuery } from 'store/rtk-query/utilityApi';
import { colors } from 'utils/constants/colors';
import { networkTypeObj, searchCategory } from 'utils/constants/globalConstant';
import { dispatch } from 'store';
import useDebounce from 'hooks/useDebouncedSearch';
import paths from 'utils/constants/paths';

// ==============================|| HEADER CONTENT - SEARCH ||============================== //

export const StyledListTag = styled('li')(({ theme }) => ({
  display: 'flex',
  borderBottom: `1px solid ${theme.palette.grey[400]}`,
  '&:last-of-type': {
    border: 'none'
  }
}));

const returnIconAndLink = (category, uuid, type) => {
  let icon, link;

  switch (category) {
    case searchCategory.INITIATIVES:
      icon = <IconNotebook stroke={1.5} />;
      link = `/initiatives/${uuid}`;
      break;
    case searchCategory.NETWORK:
      switch (type) {
        case networkTypeObj.PERSONS:
          icon = <IconUserSquareRounded stroke={1.5} />;
          link = `/network/${uuid}/${type}`;
          break;
        case networkTypeObj.ENTERPRISES:
          icon = <IconBuildingSkyscraper stroke={1.5} />;
          link = `/network/${uuid}/${type}`;
          break;
      }
      break;
    default:
      icon = <></>;
      link = '';
  }

  return { icon, link };
};
const handleSearch = (func, params) => {
  if (params?.keyword?.length >= 2) {
    func(params);
  }
};
const generateTitle = (option) => {
  return (
    (option?.firstName && option?.lastName && `${(option?.academicTitles || '') + ' ' + option?.firstName + ' ' + option?.lastName}`) ||
    option?.name ||
    option?.title
  );
};

const Search = ({ startIcon, setKeyword, keyword }) => {
  const { pathname } = useLocation();
  const isReactor = pathname === paths.Reactor.url;
  const [params, setParams] = useSearchParams();
  const searchedTerms = params.get('searchedTerms');
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [globalSearch, { data = [], isLoading: globalSearchLoading }] = useLazyGlobalSearchQuery({ skip: !keyword });
  const intl = useIntl();

  useEffect(() => {
    if (!keyword) {
      params.delete('searchedTerms');
      setParams(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, params]);

  const debouncedSearch = useDebounce(handleSearch, 500);
  const handleInputChange = (e) => {
    const { value } = e.target;
    setOpen(!!value);
    setKeyword(value);
    debouncedSearch(globalSearch, { keyword: value, searchIn: '' });
    if (!value) {
      params.delete('searchedTerms');
      setParams(params);
      dispatch(utilityApi.util.resetApiState());
    }
  };

  const CustomRenderOption = (props, option) => {
    const handleClick = (e) => {
      const { link } = returnIconAndLink(option?.category, option?.uuid, option?.networkType);
      if (link) {
        navigate(link);
        setOpen(false);
      }
      return props.onClick(e);
    };

    const { icon } = returnIconAndLink(option?.category, option?.uuid, option?.networkType);

    const renderTitle = () => {
      const title = generateTitle(option);
      const regex = new RegExp(`(${keyword.trim().split(' ').join('|')})`, 'gi');
      const parts = title.split(regex);

      return <Typography>{parts.map((part, index) => (regex.test(part) ? <strong key={index}>{part}</strong> : part))}</Typography>;
    };

    return (
      <StyledListTag {...props} onClick={(e) => handleClick(e)}>
        <Stack>
          {icon &&
            cloneElement(icon, {
              style: { marginRight: '16px', color: colors.default }
            })}
        </Stack>
        <Stack>
          {renderTitle()}
          <Typography fontSize={10}>{option?.category}</Typography>
          <Typography
            fontSize={10}
            dangerouslySetInnerHTML={{ __html: option?.teaser }}
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitBoxOrient: 'vertical',
              wordBreak: 'break-word',
              fontStyle: 'italic'
            }}
          />
        </Stack>
      </StyledListTag>
    );
  };

  CustomRenderOption.propTypes = {
    onClick: PropTypes.func
  };

  const handleOptionChange = (event, value) => {
    setKeyword(value);
  };

  const handleNavigate = (e) => {
    if (e.key === 'Enter' && !isReactor) {
      navigate({
        pathname: paths.Reactor.url,
        search: new URLSearchParams({
          searchedTerms: typeof keyword === 'object' ? searchedTerms : keyword
        }).toString()
      });
    } else if (e.key === 'Enter' && isReactor) {
      params.set('searchedTerms', typeof keyword === 'object' ? searchedTerms : keyword);
      setParams(params);
    }
    setOpen(false);
  };

  const filterOptions = (options, { inputValue }) => {
    const keywords2 = inputValue.toLowerCase().split(' ');
    const data2 = options.filter((option) =>
      keywords2.some(
        (keyword1) =>
          option.title?.toLowerCase().includes(keyword1) ||
          option.teaser?.toLowerCase().includes(keyword1) ||
          option.category?.toLowerCase().includes(keyword1) ||
          option?.firstName?.toLowerCase().includes(keyword1) ||
          option?.lastName?.toLowerCase().includes(keyword1) ||
          `${option?.firstName + ' ' + option?.lastName}`?.toLowerCase()?.includes(keyword1)
      )
    );
    return data2;
  };

  const { palette } = useTheme();
  return (
    <Box sx={startIcon && { width: '100%', ml: { xs: 4, sm: 3, md: 8 } }}>
      <Autocomplete
        id="grouped-demo"
        value={typeof keyword === 'object' ? { name: searchedTerms } : { name: keyword }}
        onFocus={handleInputChange}
        getOptionLabel={(option) =>
          (option?.firstName && option?.lastName && `${option?.firstName + ' ' + option?.lastName}`) ||
          option?.name ||
          option?.title + option?.teaser ||
          ''
        }
        onBlur={() => {
          setOpen(false);
        }}
        options={data}
        onChange={(e, v) => {
          return handleOptionChange(e, v);
        }}
        filterOptions={filterOptions}
        renderOption={CustomRenderOption}
        open={open}
        onKeyDown={handleNavigate}
        loading={globalSearchLoading}
        renderInput={(rest) => (
          <TextField
            {...rest}
            size={'medium'}
            variant="outlined"
            className={isReactor ? 'reactor-search' : 'search'}
            sx={{
              width: { xs: '100%', md: '60%' },
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
                padding: `8px !important`,
                fontSize: '16px'
              }
            }}
            InputProps={{
              spellCheck: true,
              ...rest.InputProps,
              startAdornment: startIcon && <IconSearch style={{ margin: '0px 4px' }} color={palette.primary.main} size={25} />,
              endAdornment: globalSearchLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <IconCornerDownLeft color={palette.primary.main} stroke={2} size={20} />
              )
            }}
            placeholder={intl.formatMessage(messages.SearchInInnofuse)}
            onChange={handleInputChange}
          />
        )}
        PaperComponent={({ children, ...other }) => {
          return (
            <Box
              {...other}
              onMouseDown={(e) => e.preventDefault()}
              sx={{
                border: `1px solid ${palette.primary.light}`,
                background: palette.background.default,
                borderRadius: '6px',
                boxShadow: `0px 4px 15px 0px ${palette.primary.lighter}`,
                '& :first-child': {
                  scrollbarWidth: 'none'
                }
              }}
            >
              {children}
            </Box>
          );
        }}
      />
    </Box>
  );
};

Search.propTypes = {
  startIcon: PropTypes.bool,
  disable: PropTypes.bool,
  endIcon: PropTypes.bool,
  setKeyword: PropTypes.func,
  keyword: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default Search;
